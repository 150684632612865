import { ReactElement, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Preferences } from '@capacitor/preferences';
import { actions, RootState, ThunkDispatchType } from "../../store";
import ResetPassword from "./ResetPassword";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PAGE_URL } from "../../constants";
import { Box } from "@mui/material";
import colors from "../../colors";
import { CABINET_AUTH_ERROR_CODE } from "../../utils/cognitoErrorUtils";
import { setAuthErrorToSessionStorage } from "../../utils/authUtils";


const ResetPasswordContainer = (): ReactElement => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const email = params.get('email') || undefined;
  const verifiedEmail = params.get('verifiedEmail') || undefined;
  const allowRequestNewCode = (params.get('allowRequestNewCode') === 'true') || false;


  const dispatch: ThunkDispatchType = useDispatch();
  const [loading, setLoading] = useState(false);
  const [verifiedUsername, setVerifiedUsername] = useState(verifiedEmail || '');
  const [resetStep, setResetStep] = useState(verifiedEmail ? 1 : 0);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [showRequestNewCode, setShowRequestNewCode] = useState(allowRequestNewCode);
  const [toastMessage, setToastMessage] = useState('');

  const handleSubmitEmail = async (values: { email: string }) => {
    if (values.email) {
      const res = await dispatch(actions.auth.startForgotPassword(values.email));

      if ('errorType' in res) {
        dispatch(actions.globalMessage.sendMessage({
          timeout: 2000,
          message: res.message,
          autoDismiss: true,
          header: '',
          position: { horizontal: 'center', vertical: 'top' },
          active: true,
          severity: "error",
        }));

        if (res.errorType === CABINET_AUTH_ERROR_CODE.SSO_REQUIRED) {
          if (res.extra?.orgCode) {
            navigate(PAGE_URL.LOGIN + `/${res.extra?.orgCode}`);
          } else {
            navigate(PAGE_URL.SSO_SEARCH);
          }
        } else if (res.errorType === CABINET_AUTH_ERROR_CODE.LOGIN_IN_WITH_GOOGLE) {
          navigate(PAGE_URL.LOGIN);
        }
      } else {
        setVerifiedUsername(values.email);
        setErrorMessage([]);
        setResetStep(1);
      }
    }
  };

  const handleResendCode = async () => {
    if (verifiedUsername && !toastMessage) {
      setToastMessage('A new code hase been sent, check your inbox.');
      const data = await dispatch(actions.auth.startForgotPassword(verifiedUsername));
      if (data && 'errorType' in data && data.message) {
        setErrorMessage([data.message]);
      } else {
        setErrorMessage([]);
        setResetStep(1);
      }
    }
  };

  const handleLogin = useCallback(async (user: string, pwd: string) => {
    if (pwd && user) {
      try {
        const data = (await dispatch(actions.auth.login(user, pwd, true)));
        if (data?.status === 400) {
          setErrorMessage([data.data["detail"].message]);
        } else if ('onLine' in navigator && !navigator.onLine) {
          setErrorMessage(['Offline - please check your internet connection']);
        } else if (data && 'id' in data) {
          // A valid response, so the email was valid. Save it.
          Preferences.set({key: 'email', value: user});
        }
      } catch (err) {
        console.error(err);
        if (err instanceof Error) {
          setErrorMessage([err.message]);
        } else {
          setErrorMessage([String(err)]);
        }
      }
    }
  }, [dispatch]);

  const handleResetPassword = useCallback( async (password: string, code: string) => {
    if (code && password) {
      setLoading(true);
      const data = await dispatch(actions.auth.confirmForgotPassword(verifiedUsername, code, password));
      if (data && 'errorType' in data && data.message && data.errorType) {
        setErrorMessage([data.message]);
        setShowRequestNewCode(true);
        setLoading(false);
      } else {
        setShowRequestNewCode(false);
        setErrorMessage([]);
        setLoading(false);
        setResetStep(2);
        setTimeout(async () => {
          // Need to log the user in to get valid tokens, then log out all sessions. 
          await handleLogin(verifiedUsername, password);
          
          setAuthErrorToSessionStorage([
            "Your password was successfully reset - please sign in with your new password."
          ]);
          dispatch(actions.auth.logout(true));
        }, 1000);
      }
    }
  }, [dispatch, verifiedUsername, handleLogin]);

  // if you are logged in this will prevent Storybook from showing this component
  if (isAuthenticated) {
    const state = location.state;
    let pathname;
    if (state?.from?.pathname) {
      pathname = state.from.pathname;
    } else {
      pathname = location.pathname !== PAGE_URL.RESET_PASS ? location.pathname : PAGE_URL.DASHBOARD;
    }
    return <Navigate to={pathname} />;
  } 

  return (
    <Box sx={{ backgroundColor: colors.greyBackdrop, height: '100%', width: '100%' }} overflow='auto'>
      <Box height='100%' paddingTop={10}>
        <ResetPassword 
          onSubmitEmail={handleSubmitEmail} 
          onResetPassword={handleResetPassword}
          onResendCode={handleResendCode}
          submissionErrors={errorMessage} 
          verifiedEmail={verifiedUsername}
          resetStep={resetStep}
          loading={loading} 
          email={email}
          offerNewCode={showRequestNewCode}
          toastMessage={toastMessage}
          onClearToastMessage={() => setToastMessage('')}
        />
      </Box>
    </Box>
  );
};

export default ResetPasswordContainer;